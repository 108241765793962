import React from "react"

import Layout from "../components/layout"
//import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <div className="o-meni-div">Zahtevana stran ne obstaja</div>
  </Layout>
)

export default NotFoundPage
